import { combineReducers } from "redux";
import UiReducer from "./UiReducer";
import DashboardReducer from "./DashboardReducer";
import SettingsReducer from "./SettingsReducer";
import ReportsReducers from "./ReportsReducers";
import MapReducer from "./MapReducer";

const RootReducer = combineReducers({
  ui: UiReducer,
  dashboard: DashboardReducer,
  settings: SettingsReducer,
  reports: ReportsReducers,
  map: MapReducer,
});

export default RootReducer;
