import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AuthProvider } from "app/contexts/FirebaseAuthContext";
import { SettingsProvider } from "app/contexts/SettingsContext";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MatxSnackbar, MatxTheme } from "./components";
// import store from "./redux/store";
import routes from "./routes";
// import "../fake-db";
import TitleProvider from "./contexts/titleContext";
import { QueryClient, QueryClientProvider  } from "@tanstack/react-query";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SnackbarProvider } from "notistack";
import { Store } from "./redux/Store_default";

const App = () => {
  const content = useRoutes(routes);
  const queryClient = new QueryClient();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        {/* <Provider store={store}> */}
        <Provider store={Store}>
          <SettingsProvider>
            <SnackbarProvider>
              <TitleProvider>
                <MatxTheme>
                  <MatxSnackbar />
                  <AuthProvider>{content}</AuthProvider>
                </MatxTheme>
              </TitleProvider>
            </SnackbarProvider>
          </SettingsProvider>
        </Provider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};

export default App;
