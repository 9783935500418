import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Reports = Loadable(lazy(() => import("./Reports")));
const AddActivity = Loadable(lazy(() => import("./AddActivity")));
const EditActivity = Loadable(lazy(() => import("./EditActivity")));
const ViewActivity = Loadable(lazy(() => import("./ViewActivity")));

const reportsRoutes = [
  { path: "reports/list", element: <Reports /> },
  // { path: "reports/list/add-report", element: <AddActivity /> },
  // { path: "reports/list/edit-report", element: <EditActivity /> },
  // { path: "reports/list/view-report/:id", element: <ViewActivity /> },
];

export default reportsRoutes;
