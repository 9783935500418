import React, { useState } from "react";

export const TitleContext = React.createContext();

export const TitleProvider = ({ children }) => {

  const schoolValueRedirect = JSON.parse(localStorage.getItem('schoolValueSchoolwise'));
  const isDrilDownSchoolwiseRedirect = localStorage.getItem('isDrilDownSchoolwise');

  // Defines hooks
  const [title, setTitle] = useState("Dashboard");
  const [page, setPage] = useState("Dashboard");
  const [path, setPath] = useState("/dashboard");
  const [wsdpReportDistrictDisplay, setWsdpReportDistrictDisplay] = useState(false);
  const [wsdpReportBlockDisplay, setWsdpReportBlockDisplay] = useState(false);
  const [totalPackagesCount, setTotalPackagesCount] = useState();
  const [totalActivitiesCount, setTotalActivitiesCount] = useState();
  const [totalOngoingActivitiesCount, setTotalOngoingActivitiesCount] = useState();
  const [districtValue, setDistrictValue] = useState(null);
  const [blockValue, setBlockValue] = useState(null);
  const [packageValue, setPackageValue] = useState(null);
  const [schoolValue, setSchoolValue] = useState(schoolValueRedirect !== null ? schoolValueRedirect : { SchoolId: '24270603801', CampusName: 'GABAT - 1 SCH' });
  const [totalSchoolsSoeCount, setTotalSchoolsSoeCount] = useState();
  const [totalSchoolsSmcCount, setTotalSchoolsSmcCount] = useState();
  const [tabIndex, setTabIndex] = useState();
  const [isDrillDown, setIsDrillDown] = useState(isDrilDownSchoolwiseRedirect !== null ? isDrilDownSchoolwiseRedirect : false);
  const [smcShow, setSmcShow] = useState(true);
  const [soeShow, setSoeShow] = useState(true);
  const [sunburstDialog, setSunburstDialog] = useState(false);
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState("");
  const [dashboardFilter, setDashboardFilter] = useState(false);
  const [districtFilterValue, setDistrictFilterValue] = useState(null);
  const [blockFilterValue, setBlockFilterValue] = useState(null);
   
  return (
    <TitleContext.Provider
      value={{
        title,
        setTitle,
        page,
        setPage,
        path,
        setPath,
        wsdpReportDistrictDisplay,
        setWsdpReportDistrictDisplay,
        wsdpReportBlockDisplay,
        setWsdpReportBlockDisplay,
        totalPackagesCount,
        setTotalPackagesCount,
        totalActivitiesCount,
        setTotalActivitiesCount,
        totalOngoingActivitiesCount,
        setTotalOngoingActivitiesCount,
        districtValue, setDistrictValue,
        blockValue, setBlockValue,
        packageValue, setPackageValue,
        schoolValue, setSchoolValue,
        totalSchoolsSoeCount,
        setTotalSchoolsSoeCount,
        totalSchoolsSmcCount,
        setTotalSchoolsSmcCount,
        tabIndex,
        setTabIndex,
        isDrillDown, setIsDrillDown,
        smcShow, setSmcShow,
        soeShow, setSoeShow,
        sunburstDialog, 
        setSunburstDialog,
        districtId, 
        setDistrictId,
        blockId, 
        setBlockId,
        dashboardFilter, 
        setDashboardFilter,
        districtFilterValue, 
        setDistrictFilterValue,
        blockFilterValue, 
        setBlockFilterValue
      }}
    >
      {children}
    </TitleContext.Provider>
  );
};

export default TitleProvider;
