import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Switch,
  TextField,
  styled,
} from "@mui/material";
import { H5, Paragraph } from "app/components/Typography";
import { Formik } from "formik";
import { Info } from "@mui/icons-material";
import { useEffect } from "react";
import { getSettingsDetails, settingsDetails } from "app/redux/actions/SettingsActions";
import { useDispatch, useSelector } from "react-redux";

const Title = styled("span")(() => ({
  fontSize: 14,
  fontWeight: 600,
  color: "#333333",
}));

const FormTitle = styled("span")(() => ({
  fontSize: 16,
  fontWeight: 600,
  color: "#333333",
}));

const Small = styled("small")(() => ({
  color: "#FFFFFF",
  // padding: "3px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: "#3668D9",
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const Preferences = () => {

  const dispatch = useDispatch();
  const settingData = useSelector((state) => state.settings.settingsData);

  useEffect(() => {
    getSettingsData();
  }, []);

  const getSettingsData = async () => {
    try {
      await dispatch(getSettingsDetails());
    } catch (error) {
      throw new Error(error);
    }
  };

  const initialValues = {
    recentWorkOrder: settingData[0]?.recent_work_order_days || "",
  };

  return (
    <Card
      sx={{
        // borderRadius: "10px",
        padding: "15px 30px",
        // border: "1px solid #E3E3E3",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important",
      }}
    >
      {/* <H5 padding={3}>General Preferences</H5> */}
      {/* <Divider /> */}

      {/* <Box padding={3}>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <TextField
              select
              fullWidth
              label="Language"
              variant="outlined"
              placeholder="Language"
              value="english"
              SelectProps={{ native: true, IconComponent: KeyboardArrowDown }}
            >
              <option value="english">English</option>
              <option value="bangla">Bangla</option>
              <option value="hindi">Hindi</option>
            </TextField>
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField variant="outlined" label="Time Zone" fullWidth value="12:00 AM" />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <H5 mb={0.5}>Early release</H5>
                <Paragraph>Get included on new Octavia features.</Paragraph>
              </Box>

              <Switch defaultChecked />
            </Stack>

            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Box>
                <H5 mb={0.5}>See info about people who view my profile</H5>
                <Paragraph>More about viewer info.</Paragraph>
              </Box>

              <Switch defaultChecked />
            </Stack>
          </Grid>
        </Grid>
      </Box> */}

      {/* <H5 padding={3}>Email Preferences</H5>
      <Divider /> */}

      {/* <Stack spacing={2} padding={3}>
        <Stack direction="row" spacing={2}>
          <Checkbox />
          <Box>
            <H5>Successful Payments</H5>
            <Paragraph>Receive a notification for every successful payment.</Paragraph>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Checkbox checked />
          <Box>
            <H5>Payouts</H5>
            <Paragraph>Receive a notification for every initiated payout.</Paragraph>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Checkbox checked />
          <Box>
            <H5>Fee Collection</H5>
            <Paragraph>Receive a notification each time you collect a fee from sales</Paragraph>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Checkbox />
          <Box>
            <H5>Invoice Payments</H5>
            <Paragraph>
              Receive a notification if a customer sends an incorrect amount to pay their invoice.
            </Paragraph>
          </Box>
        </Stack>
      </Stack> */}

      {/* <Stack direction="row" spacing={3} padding={3}>
        <Button variant="contained">Save Changes</Button>
        <Button variant="outlined">Cancel</Button>
      </Stack> */}

      <FormTitle sx={{ mt: 2, mb: 1 }}>Settings</FormTitle>

      <Divider sx={{ mt: 1, mb: 2 }} />

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          let formData = new FormData();
        
          // save all entered details in formData
          formData.append("recentWorkOrder", values.recentWorkOrder);

          const params = new URLSearchParams();
          params.append("recent_work_order_days", values.recentWorkOrder);
          dispatch(settingsDetails(params)); 

          // resetForm({ values: "" }); // reset all values in form  
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item lg={2.2} md={2} sm={4} xs={12}>
                <Title>Recent Work Order Days</Title>
              </Grid>

              <Grid item lg={9.8} md={10} sm={8} xs={12}>
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                  <TextField
                    label="Enter no. of days"
                    variant="outlined"
                    name="recentWorkOrder"
                    size="small"
                    value={values.recentWorkOrder || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      style: { fontSize: "14px", fontFamily: "Poppins" },
                    }}
                    InputLabelProps={{ className: "add-formlabel" }}
                    FormHelperTextProps={{ className: "input_labelerror_props" }}
                    helperText={touched.recentWorkOrder && errors.recentWorkOrder}
                    error={errors.recentWorkOrder && touched.recentWorkOrder}
                  />
                  <Small sx={{ display: "flex", alignItems:"center", marginTop:"18px", marginLeft:"10px"}}>
                    <Info sx={{ height: "15px"}}/> 
                    WO within "last no. of days" considered as a recent work order.
                  </Small>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Box mt={2}>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default Preferences;
