// import { Icon, IconButton, Snackbar } from "@mui/material";
// import React from "react";

// const MatxSnackbar = ({
//   open,
//   message,
//   handleClose,
//   duration = 6000,
//   vertical = "bottom",
//   horizontal = "center",
// }) => {
//   return (
//     <Snackbar
//       open={open}
//       onClose={handleClose}
//       autoHideDuration={duration}
//       message={<span id="message-id">{message}</span>}
//       ContentProps={{ "aria-describedby": "message-id" }}
//       anchorOrigin={{ vertical: { vertical }, horizontal: { horizontal } }}
//       action={[
//         <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
//           <Icon>close</Icon>
//         </IconButton>,
//       ]}
//     />
//   );
// };

// export default MatxSnackbar;

import { Box, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
// import { clearSnackbar } from "../redux/actions/UiActions";
import { clearSnackbar } from "app/redux/actions/UiAction";
import Alert from "@mui/material/Alert";

const Toast = () => {

  const dispatch = useDispatch()
  const { snackbarMessage, snackbarOpen, variant } = useSelector((state) => state.ui)

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Box>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionProps={{ enter: true, exit: false }}
        onClose={handleClose}
      >
        <Alert variant="filled" onClose={handleClose} severity={variant === "success" ? "success" : "error"} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Toast;
