import axios from "axios";

const axiosInstance = axios.create({ // create axios baseurl for all api's
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type' : "application/x-www-form-urlencoded",
    }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error?.response?.data || "Something went wrong!")
);

export default axiosInstance;
