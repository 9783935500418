import axios from "axios.js";
import { showSnackbar } from "./UiAction";

// Defines all states values
export const GET_SOE_SCHOOLS_DATA = "GET_SOE_SCHOOLS_DATA";
export const GET_SOE_ACTIVITIES_DATA = "GET_SOE_ACTIVITIES_DATA";
export const GET_SOE_PACKAGES_DATA = "GET_SOE_PACKAGES_DATA";
export const GET_SMC_SCHOOLS_DATA = "GET_SMC_SCHOOLS_DATA";
export const GET_SMC_ACTIVITIES_DATA = "GET_SMC_ACTIVITIES_DATA";
export const GET_ACTIVITIES_BARCHART_DATA = "GET_ACTIVITIES_BARCHART_DATA";
export const GET_SUNBURST_CHART_DATA = "GET_SUNBURST_CHART_DATA";

export const getSoeSchoolsData = (districtId, blockId) => (dispatch) => {
  // call dashboard soe schools data get api
  axios.get(`/dashboard/schools/soe?districtId=${districtId}&blockId=${blockId}`)
    .then((res) => {
      dispatch({
        type: GET_SOE_SCHOOLS_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'top', "error"));
    });
};

export const getSoeActivitiesData = (districtId, blockId) => (dispatch) => {
  // call dashboard soe activites data get api
  axios.get(`/dashboard/activites/soe?districtId=${districtId}&blockId=${blockId}`)
    .then((res) => {
      dispatch({
        type: GET_SOE_ACTIVITIES_DATA,
        payload: res.data,
      });
    });
};

export const getSoePackagesData = (districtId, blockId) => (dispatch) => {
  // call dashboard soe packages data get api
  axios.get(`/dashboard/packages/soe?districtId=${districtId}&blockId=${blockId}`)
    .then((res) => {
      dispatch({
        type: GET_SOE_PACKAGES_DATA,
        payload: res.data,
      });
    });
};

export const getSmcSchoolsData = (districtId, blockId) => (dispatch) => {
  // call dashboard smc schools data get api
  axios.get(`/dashboard/schools/smc?districtId=${districtId}&blockId=${blockId}`)
    .then((res) => {
      dispatch({
        type: GET_SMC_SCHOOLS_DATA,
        payload: res.data,
      });
    });
};

export const getSmcActivitiesData = (districtId, blockId) => (dispatch) => {
  // call dashboard smc activities data get api
  axios.get(`/dashboard/activities/smc?districtId=${districtId}&blockId=${blockId}`)
    .then((res) => {
      dispatch({
        type: GET_SMC_ACTIVITIES_DATA,
        payload: res.data,
      });
    });
};

export const getActivitiesBarChartData = (districtId, blockId) => (dispatch) => {
  // call dashboard soe bar chart data get api
  axios.get(`/dashboard/bar_chart/soe?districtId=${districtId}&blockId=${blockId}`)
    .then((res) => {
      dispatch({
        type: GET_ACTIVITIES_BARCHART_DATA,
        payload: res.data,
      });
    });
};

export const getSunburstChartData = (districtId, blockId) => (dispatch) => {
  // call dashboard soe bar chart data get api
  axios.get(`/dashboard/sunburstchart?districtId=${districtId}&blockId=${blockId}`)
    .then((res) => {
      dispatch({
        type: GET_SUNBURST_CHART_DATA,
        payload: res.data,
      });
    });
};