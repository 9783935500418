import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Settings = Loadable(lazy(() => import("./Settings")));

const settingsRoutes = [
  { path: "settings", element: <Settings /> },
];

export default settingsRoutes;
