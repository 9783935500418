import { SNACKBAR_OPEN, SNACKBAR_CLEAR } from "../actions/UiAction.js";

const initialState = { // initialize states
  snackbarOpen: false,
  snackbarMessage: null,
  variant: "success",
  vertical: "top",
};

const UiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN: // save open snackbar data into state
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        vertical: action.vertical,
        variant: action.variant,
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
};

export default UiReducer;
