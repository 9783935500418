import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';

const useNotification = () => {
  const [conf, setConf] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (conf?.msg) {
      let variant = 'info';
      let vertical = 'top';
      let horizontal = 'right';
      if (conf.variant) {
        variant = conf.variant;
      }
      enqueueSnackbar(conf.msg, {
        key: new Date().getTime() + Math.random(),
        variant: variant,
        autoHideDuration: 5000,
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        ),
        anchorOrigin: { vertical, horizontal },
      });
    }
  }, [conf]);
  return [conf, setConf];
};

export default useNotification;
