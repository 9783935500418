import axios from "axios.js";
import { showSnackbar } from "./UiAction";

// Defines all state values
export const GET_SETTINGS_DETAILS = "GET_SETTINGS_DETAILS";
export const SETTINGS_DETAILS = "SETTINGS_DETAILS";

// settings details get api
export const getSettingsDetails = () => (dispatch) => {
  axios.get("/setting/last_update_date").then((res) => {
    dispatch({
      type: GET_SETTINGS_DETAILS,
      payload: res.data,
    });
  });
};

// settings details api
export const settingsDetails = (data) => (dispatch) => {
  axios
    .post("/setting/last_update_date", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", // set content type as multipart/form-data
      },
    })
    .then((response) => {
      dispatch(showSnackbar(response.data.message, "top", "success"));
      dispatch({
        type: SETTINGS_DETAILS,
        payload: response.data,
      });
      dispatch(getSettingsDetails());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, "top", "error"));
    });
};
