export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const showSnackbar = (message, vertical, variant) => { // show snackbar action
  return (dispatch) => {
    dispatch({ type: 'SNACKBAR_OPEN', message, vertical, variant });
  };
};
 
export const clearSnackbar = () => { // clear snackbar action
  return (dispatch) => {
    dispatch({ type: 'SNACKBAR_CLEAR' });
  };
};
