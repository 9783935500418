import { GET_SETTINGS_DETAILS } from "../actions/SettingsActions";

const initialState = { // initialize states
  settingsData: [],
};

const SettingsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_DETAILS: {
      return { ...state, settingsData: action.payload.data ? action.payload.data : [] };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default SettingsReducer;
