import axios from "axios.js";

// Defines all states values
export const GET_MAP_DATA = "GET_MAP_DATA";
export const GET_MARKER_POSITION_DATA = "GET_MARKER_POSITION_DATA";

// call map data get api
export const getMapData = () => (dispatch) => {
  axios.get("/report/map").then((res) => {
    dispatch({
      type: GET_MAP_DATA,
      payload: res.data,
    });
  });
};

export const getSchoolsMarkerPositionData = (BlockId, SchoolId) => (dispatch) => {
  axios.get(`/report/map/block/${BlockId}/school/${SchoolId}/trp`).then((res) => {
    dispatch({
      type: GET_MARKER_POSITION_DATA,
      payload: res.data,
    });
  });
};
