import {
  GET_ACTIVITYWISE_REPORT_BLOCK_DATA,
  GET_ACTIVITYWISE_REPORT_DATA,
  GET_ACTIVITYWISE_REPORT_PACKAGE_DATA,
  GET_ACTIVITYWISE_REPORT_SCHOOL_DATA,
  GET_PACKAGES_REPORT_BLOCK_DATA,
  GET_PACKAGES_REPORT_DATA,
  GET_PACKAGES_REPORT_SCHOOLS_PACKAGENAME_DATA,
  GET_PACKAGES_REPORT_SCHOOLS_PACKAGES_DATA,
  GET_PACKAGES_REPORT_SCHOOL_DATA,
  GET_SCHOOLWISE_REPORT_BLOCK_DATA,
  GET_SCHOOLWISE_REPORT_DATA,
  GET_SCHOOLWISE_REPORT_SCHOOLS_DATA,
  GET_SCHOOL_REPORT_DATA,
} from "../actions/ReportsActions";

const initialState = {
  // initialize states
  schoolWiseReportData: [],
  activityWiseReportData: [],
  packagesReportData: [],
  schoolReportData: [],
};

const ReportsReducers = function (state = initialState, action) {
  switch (action.type) {
    case GET_SCHOOLWISE_REPORT_DATA: {
      return { ...state, schoolWiseReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_SCHOOLWISE_REPORT_BLOCK_DATA: {
      return { ...state, schoolWiseReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_SCHOOLWISE_REPORT_SCHOOLS_DATA: {
      return { ...state, schoolWiseReportData: action.payload.data ? action.payload.data : [] };
    }

    case GET_ACTIVITYWISE_REPORT_DATA: {
      return { ...state, activityWiseReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_ACTIVITYWISE_REPORT_PACKAGE_DATA: {
      return { ...state, activityWiseReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_ACTIVITYWISE_REPORT_BLOCK_DATA: {
      return { ...state, activityWiseReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_ACTIVITYWISE_REPORT_SCHOOL_DATA: {
      return { ...state, activityWiseReportData: action.payload.data ? action.payload.data : [] };
    }

    case GET_PACKAGES_REPORT_DATA: {
      return { ...state, packagesReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_PACKAGES_REPORT_BLOCK_DATA: {
      return { ...state, packagesReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_PACKAGES_REPORT_SCHOOL_DATA: {
      return { ...state, packagesReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_PACKAGES_REPORT_SCHOOLS_PACKAGES_DATA: {
      return { ...state, packagesReportData: action.payload.data ? action.payload.data : [] };
    }
    case GET_PACKAGES_REPORT_SCHOOLS_PACKAGENAME_DATA: {
      return { ...state, packagesReportData: action.payload.data ? action.payload.data : [] };
    }

    case GET_SCHOOL_REPORT_DATA: {
      return { ...state, schoolReportData: action.payload ? action.payload : [] };
    }
    
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ReportsReducers;
