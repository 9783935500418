import { 
  GET_ACTIVITIES_BARCHART_DATA, 
  GET_SMC_ACTIVITIES_DATA, 
  GET_SMC_SCHOOLS_DATA, 
  GET_SOE_ACTIVITIES_DATA, 
  GET_SOE_PACKAGES_DATA, 
  GET_SOE_SCHOOLS_DATA, 
  GET_SUNBURST_CHART_DATA 
} from "../actions/DashboardActions";

const initialState = { // initialize states
  soeSchoolsData: [],
  soeActivitiesData: [],
  soePackagesData: [],
  smcSchoolsData: [],
  smcActivitiesData: [],
  activityBarChartData: [],
  sunburstChartData: [],
};

const DashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SOE_SCHOOLS_DATA: {
      return { ...state, soeSchoolsData: (action.payload.data) ? action.payload.data: [] };
    }
    case GET_SOE_ACTIVITIES_DATA: {
      return { ...state, soeActivitiesData: (action.payload.data) ? action.payload.data: [] };
    }
    case GET_SOE_PACKAGES_DATA: {
      return { ...state, soePackagesData: (action.payload.data) ? action.payload.data: [] };
    }
    case GET_SMC_SCHOOLS_DATA: {
      return { ...state, smcSchoolsData: (action.payload.data) ? action.payload.data: [] };
    }
    case GET_SMC_ACTIVITIES_DATA: {
      return { ...state, smcActivitiesData: (action.payload.data) ? action.payload.data: [] };
    }
    case GET_ACTIVITIES_BARCHART_DATA: {
      return { ...state, activityBarChartData: (action.payload.data) ? action.payload.data: [] };
    }
    case GET_SUNBURST_CHART_DATA: {
      return { ...state, sunburstChartData: (action.payload.data) ? action.payload.data: [] };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default DashboardReducer;
