import { GET_MAP_DATA, GET_MARKER_POSITION_DATA } from "../actions/MapActions";

const initialState = {
  // initialize states
  mapData: [],
  markerPositionData: [],
};

const MapReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_MAP_DATA: {
      return { ...state, mapData: action.payload ? action.payload : [] };
    }
    case GET_MARKER_POSITION_DATA: {
      return { ...state, markerPositionData: action.payload.data ? action.payload.data : [] };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default MapReducer;
