import axios from "axios.js";
import { showSnackbar } from "./UiAction";

// Defines all states values
export const GET_SCHOOLWISE_REPORT_DATA = "GET_SCHOOLWISE_REPORT_DATA";
export const GET_SCHOOLWISE_REPORT_BLOCK_DATA = "GET_SCHOOLWISE_REPORT_BLOCK_DATA";
export const GET_SCHOOLWISE_REPORT_SCHOOLS_DATA = "GET_SCHOOLWISE_REPORT_SCHOOLS_DATA";

export const GET_ACTIVITYWISE_REPORT_DATA = "GET_ACTIVITYWISE_REPORT_DATA";
export const GET_ACTIVITYWISE_REPORT_PACKAGE_DATA = "GET_ACTIVITYWISE_REPORT_PACKAGE_DATA";
export const GET_ACTIVITYWISE_REPORT_BLOCK_DATA = "GET_ACTIVITYWISE_REPORT_BLOCK_DATA";
export const GET_ACTIVITYWISE_REPORT_SCHOOL_DATA = "GET_ACTIVITYWISE_REPORT_SCHOOL_DATA";

export const GET_PACKAGES_REPORT_DATA = "GET_PACKAGES_REPORT_DATA";
export const GET_PACKAGES_REPORT_BLOCK_DATA = "GET_PACKAGES_REPORT_BLOCK_DATA";
export const GET_PACKAGES_REPORT_SCHOOL_DATA = "GET_PACKAGES_REPORT_SCHOOL_DATA";
export const GET_PACKAGES_REPORT_SCHOOLS_PACKAGES_DATA = "GET_PACKAGES_REPORT_SCHOOLS_PACKAGES_DATA";
export const GET_PACKAGES_REPORT_SCHOOLS_PACKAGENAME_DATA = "GET_PACKAGES_REPORT_SCHOOLS_PACKAGENAME_DATA";

export const GET_SCHOOL_REPORT_DATA = "GET_SCHOOL_REPORT_DATA";

// call schoolwise report data get api
export const getSchoolWiseReportData = () => (dispatch) => {
  axios.get("/report/summary/school").then((res) => {
    dispatch({
      type: GET_SCHOOLWISE_REPORT_DATA,
      payload: res.data,
    });
  });
};

// call schoolwise report data get api
export const getSchoolWiseReportBlockData = (data) => (dispatch) => {
  axios.get(`/report/summary/school/block?DistrictId=${data}`).then((res) => {
    dispatch({
      type: GET_SCHOOLWISE_REPORT_BLOCK_DATA,
      payload: res.data,
    });
  });
};

// call schoolwise report data get api
export const getSchoolWiseReportSchoolsData = (data) => (dispatch) => {
  axios.get(`/report/summary/school/block/schools?BlockId=${data}`).then((res) => {
    dispatch({
      type: GET_SCHOOLWISE_REPORT_SCHOOLS_DATA,
      payload: res.data,
    });
  });
};

//ActivityWise Report's API
// call activitywise report data get api
export const getActivityWiseReportData = () => (dispatch) => {
  axios.get("/report/summary/activity").then((res) => {
    dispatch({
      type: GET_ACTIVITYWISE_REPORT_DATA,
      payload: res.data,
    });
  });
};

// call activitywise report's package data get api
export const getActivityWiseReportPackageData = (data) => (dispatch) => {
  axios.get(`/report/summary/activity/block/schools/packages?schoolId=${data}`).then((res) => {
    dispatch({
      type: GET_ACTIVITYWISE_REPORT_PACKAGE_DATA,
      payload: res.data,
    });
  });
};

// call activitywise report's block data get api
export const getActivityWiseReportBlockData = (data) => (dispatch) => {
  axios.get(`/report/summary/activity/block?DistrictId=${data}`).then((res) => {
    dispatch({
      type: GET_ACTIVITYWISE_REPORT_BLOCK_DATA,
      payload: res.data,
    });
  });
};

// call activitywise report's school data get api
export const getActivityWiseReportSchoolData = (data) => (dispatch) => {
  axios.get(`/report/summary/activity/block/schools?BlockId=${data}`).then((res) => {
    dispatch({
      type: GET_ACTIVITYWISE_REPORT_SCHOOL_DATA,
      payload: res.data,
    });
  });
};

//Packages Report's API
//call packages report data get api
export const getPackagesReportData = () => (dispatch) => {
  axios.get("/report/summary/district").then((res) => {
    dispatch({
      type: GET_PACKAGES_REPORT_DATA,
      payload: res.data,
    });
  });
};

export const getPackagesReportBlockData = (data) => (dispatch) => {
  axios.get(`/report/summary/block?DistrictId=${data}`).then((res) => {
    dispatch({
      type: GET_PACKAGES_REPORT_BLOCK_DATA,
      payload: res.data,
    });
  });
};

export const getPackagesReportSchoolData = (data) => (dispatch) => {
  axios.get(`/report/summary/block/schools?BlockId=${data}`).then((res) => {
    dispatch({
      type: GET_PACKAGES_REPORT_SCHOOL_DATA,
      payload: res.data,
    });
  });
};

export const getPackagesReportSchoolsPackagesData = (SchoolId) => (dispatch) => {
  axios.get(`/report/summary/block/school/packages?schoolId=${SchoolId}`).then((res) => {
    dispatch({
      type: GET_PACKAGES_REPORT_SCHOOLS_PACKAGES_DATA,
      payload: res.data,
    });
  });
};

export const getPackagesReportPackageNameData = (schoolId, package_name) => (dispatch) => {
  axios.get(`/report/summary/block/schools/packages?schoolId=${schoolId}&packageName=${package_name}`).then((res) => {
    dispatch({
      type: GET_PACKAGES_REPORT_SCHOOLS_PACKAGENAME_DATA,
      payload: res.data,
    });
  });
};

//School Report's API
//call school report data get api
export const getSchoolReportData = (SchoolId) => (dispatch) => {
  axios.get(`/report/school/${SchoolId}`).then((res) => {
    dispatch({
      type: GET_SCHOOL_REPORT_DATA,
      payload: res.data,
    });
  });
};